export class InstallationLog {
  public user: string;
  public actionKey: string;
  public timestamp: Date;
  public installationDate: Date;
  public targetAsset: string;
  public targetDctu: string;
  public completed: boolean;
  public rejected: boolean;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
