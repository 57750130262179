<ng-template #installerTemplate let-model="item" let-index="index">
    <p class="no-margin"><strong>{{model.name}}</strong></p>
</ng-template>

<div class="form-group row">
    <div class="col-md-6" [ngClass]="{'has-error' : installerError, 'valid': !installerError && installationTask.installer}">
        <label *ngIf="!removeMode" class="input-label">{{'installation.installer' | translate}}</label>
        <label *ngIf="removeMode" class="input-label">{{'installation.remover' | translate}}</label>
        <i *ngIf="!removeMode" class="fa fa-question-circle" tooltip="Select the name of the person that has done the installation." placement="right"></i>
        <i *ngIf="removeMode" class="fa fa-question-circle" tooltip="Select the name of the person that has done the removal." placement="right"></i>
        <yf-typeahead [isReadonly]="isReadonly"
                      [readonlyValue]="installationTask.installer"
                      [items]="typeaheadInstallers"
                      [template]="installerTemplate"
                      (onTypeaheadSelect)="installationTask.installer = $event.name"
                      (onError)="setInstallerError($event)"></yf-typeahead>
    </div>

    <div class="col-md-3" [ngClass]="{'has-error' : installationSiteError, 'valid': !installationSiteError && installationTask.installationSite}">
        <label class="input-label">{{'installation.site' | translate}}</label>
        <i *ngIf="!removeMode" class="fa fa-question-circle" tooltip="Select the location where the installation was done." placement="top"></i>
        <i *ngIf="removeMode" class="fa fa-question-circle" tooltip="Select the location where the removal was done." placement="top"></i>
        <yf-typeahead [isReadonly]="isReadonly"
                      [readonlyValue]="installationTask.installationSite"
                      [items]="installationSites"
                      (onTypeaheadSelect)="installationTask.installationSite = $event.name"
                      (onError)="setInstallationSiteError($event)"></yf-typeahead>
    </div>

    <div class="col-md-3" [ngClass]="{'has-error' : installationDateError, 'valid': !installationDateError && installationTask.installationDate}">
        <label *ngIf="!removeMode" class="input-label">{{'installation.date' | translate}}</label>
        <label *ngIf="removeMode" class="input-label">{{'installation.removeDate' | translate}}</label>
        <i *ngIf="!removeMode" class="fa fa-question-circle" tooltip="Select the date when the installation was done." placement="top"></i>
        <i *ngIf="removeMode" class="fa fa-question-circle" tooltip="Select the date when the removal was done." placement="top"></i>
        <div class="required-field-block" *ngIf="isReadonly">
            <input type="text" disabled class="form-control" value="{{installationTask.installationDate | date:'yyyy-MM-dd'}}">
        </div>
        <div class="required-field-block" *ngIf="!isReadonly">
            <input type="text"
                   readonly
                   name="installationDate"
                   class="form-control default-background-color"
                   [bsConfig]="bsConfig"
                   [maxDate]="maxDate"
                   (focus)="setInstallationDateErrorWhenFocus()"
                   (ngModelChange)="setInstallationDate($event)"
                   [ngModel]="installationTask.installationDate"
                   bsDatepicker>

            <div class="required-icon" [ngClass]="{'disabled': isReadonly}">
                <div class="text">*</div>
            </div>
        </div>
    </div>
</div>

<div class="form-group">
    <label class="input-label" for="comment">{{'installation.comment' | translate}}</label>
    <i class="fa fa-question-circle" tooltip="Enter any additional information about the installation that you might find important." placement="right"></i>
    <textarea id="comment"
              [disabled]="isReadonly"
              class="form-control"
              name="comment"
              rows=3
              [(ngModel)]="installationTask.comment"
              [placeholder]="'installation.comment' | translate"></textarea>
</div>
