import { NgModule } from '@angular/core';
import { DctuService } from './dctu.service';
import { AssetService } from './asset.service';
import { InstallerService } from './installer.service';
import { LogService } from './log.service';
import { MeService } from './me.service';
import { InstallationTaskService } from './installation-task.service';
import { AuthService } from './auth.service';
import { InstallationSiteService } from './installation-site.service';
import { LoggedInService } from './logged-in.service';
import { SearchTagService } from './search-tag.service';
import { HasFilesChangedService } from './hasFilesChanged.service';

@NgModule({
  providers: [
    AssetService,
    AuthService,
    DctuService,
    InstallationSiteService,
    InstallationTaskService,
    InstallerService,
    MeService,
    LogService,
    LoggedInService,
    SearchTagService,
    HasFilesChangedService,
  ]
})
export class ServiceModule {}
