<h2>{{'home.replace_dctu' | translate}}</h2>

<div class="row">
    <div *ngIf="loading" class="loader-zone">
      <div class="loader">{{'generic.loading' | translate}}</div>
    </div>
    <div *ngIf="!loading">
        <div class="col-md-9">
            <form autocomplete="off">
                <div class="form-group row">
                    <div class="form-group col-md-5" [ngClass]="{'has-error' : assetError, 'valid': !assetError && this.installationTask.asset.name}">
                        <label class="input-label">{{'installation.replace.dctu-asset' | translate}}</label>
                        <i class="fa fa-question-circle" tooltip="Select the asset where the GPS-device (DCTU) has been replaced. The replaced DCTU is displayed within parentheses, e.g. (70d0ab6)." placement="right"></i>
                        <yf-typeahead [isReadonly]="loadedTask" [readonlyValue]="installationTask.asset.name"
                                      [items]="assets" (onTypeaheadSelect)="installationTask.asset = $event"
                                      (onError)="assetError = $event"></yf-typeahead>
                    </div>
                    <div class="col-md-2 center-block to-block text-center">
                        {{'installation.replace.with' | translate}}
                    </div>
                    <div class="form-group col-md-5" [ngClass]="{'has-error' : dctuError, 'valid': !dctuError && this.installationTask.dctu.name}">
                        <label class="input-label">{{'installation.dctu' | translate}}</label>
                        <i class="fa fa-question-circle" tooltip="Select the identification name/number of the new GPS-device (DCTU), six or seven letters and figures long, e.g. 101302 or 70d0af3." placement="right"></i>

                        <yf-typeahead [isReadonly]="loadedTask"
                                      [readonlyValue]="installationTask.dctu.name"
                                      [items]="dctus"
                                      (onTypeaheadSelect)="setDctuOn(this.installationTask, $event)"
                                      (onError)="dctuError = $event"></yf-typeahead>
                    </div>
                </div>

                <yf-installation-form-dctu-details [connectedToPower]="installationTask.connectedToPower"
                                                   [dctu]="installationTask.dctu"
                                                   [isReadonly]="loadedTask"
                                                   [sensors]="installationTask.sensors"
                                                   (onToggleConnectedToPower)="installationTask.connectedToPower = $event"
                                                   (onSetSensors)="installationTask.sensors = $event"></yf-installation-form-dctu-details>

                <yf-installation-form-general-details [(installationTask)]="installationTask"
                                                      [isReadonly]="loadedTask"
                                                      (onInstallerError)="installerError = $event"
                                                      (onInstallationSiteError)="installationSiteError = $event"
                                                      (onInstallationDateError)="installationDateError = $event"></yf-installation-form-general-details>

                <yf-installation-form-files [(files)]="installationTask.files"></yf-installation-form-files>

                <yf-installation-form-placement [(installationTask)]="installationTask"
                                                [isReadonly]="loadedTask"></yf-installation-form-placement>

                <div class="form-group text-right">
                    <i class="pull-left reported" *ngIf="loadedTask">Reported by: {{installationTask.reporter}} {{installationTask.created | date:'yyyy-MM-dd HH:mm z'}}</i>
                    <button *ngIf="!loadedTask" (click)="createInstallationTask()" [disabled]="invalidForm() || submitting" class="btn btn-primary" type="submit">
                        <i *ngIf="submitting" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i> {{'installation.replace.submit' | translate}}
                    </button>

                    <button *ngIf="loadedTask && hasFilesChangedService.hasFilesChanged" (click)="editSubmit()" [disabled]="invalidForm() || submitting" class="btn btn-primary" type="submit" data-cy="installationEditSubmit">
                      <i *ngIf="submitting" class="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i> {{'installation.editSubmit' | translate}}
                    </button>
                </div>
            </form>
        </div>

        <div class="col-md-3" *ngIf="!loading">
            <h3>{{'installation.attached_files' | translate}}</h3>
            <ul class="files">
              <a download="{{file.name}}" *ngFor="let file of installationTask.files" [href]="'/api/installation-task/' + taskId + '/file/' + file.name" target="_blank" data-cy="fileNameAndDate">
                <li>{{file.name}}</li>
                <small>{{file.user}} {{file.created}}</small>
              </a>
            </ul>

            <h3>{{'installation.preview_images' | translate}}</h3>
            <img class="img-responsive padding-bottom" *ngFor="let image of getImagesFromGeneralFileList(installationTask.files)" [src]="sanitize(image.data)" data-cy="previewImages">
        </div>
    </div>
</div>
