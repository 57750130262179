import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Asset } from './entities/asset';
import { Json } from './utils/json';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AssetService {
    private http: HttpClient;
    private url = '/api/asset';

    constructor(http: HttpClient) {
        this.http = http;
    }

    public getAssets(free = true, all = false): Observable<Asset[]> {
        return this.http.get(`${this.url}?free=${free}&all=${all}`).pipe(
            map(Json.manyOf(Asset)));
    }
}
