import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Observable, Observer, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'yf-typeahead',
  templateUrl: './typeahead.component.html'
})
export class TypeaheadComponent implements OnChanges {
  @Input() public isReadonly: boolean;
  @Input() public readonlyValue: string;
  @Input() public createNewWhenNoResults = false;
  @Input() public items: any;
  @Input() public template;

  @Output() public onCreateNewItem = new EventEmitter();
  @Output() public onTypeaheadSelect = new EventEmitter();
  @Output() public onError = new EventEmitter();

  public selected: string;
  public states: string[] = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Dakota',
    'North Carolina',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming'
  ];

  public dataSource: Observable<any>;
  public search: string;
  public item: any;

  public ngOnChanges(changes: any): void {
    if (changes.items && changes.items.currentValue) {
      this.setDataSource(changes.items.currentValue);
    }
  }

  public typeaheadOnSelect(event) {
    this.item = event.item;
    this.onTypeaheadSelect.emit(event.item);
    this.onError.emit(false);
  }

  public typeaheadNoResults() {
    if (this.createNewWhenNoResults) {
      if (this.search && this.search !== '') {
        let matches = this.items.filter((item) => {
          return item.name.toLowerCase() === this.search.toLowerCase();
        });
        if (matches.length === 1) {
          this.onTypeaheadSelect.emit(matches[0]);
        } else {
          this.onCreateNewItem.emit(this.search);
        }
        this.onError.emit(false);
      } else {
        this.onError.emit(true);
      }
    } else {
      if (this.hasError()) {
        this.onError.emit(true);
      }
    }
  }

  public checkError() {
    if (this.hasError()) {
      this.onError.emit(true);
    }
  }

  public autoSelectOnMatch(value: string) {
    let matches = this.items.filter((item) => {
      return item.name.toLowerCase() === value.toLowerCase();
    });
    if (matches.length === 1) {
      this.typeaheadOnSelect({item: matches[0]});
    }
  }

  private hasError(): boolean {
    return !this.item || this.item.name !== this.search || this.item === '';
  }

  private setDataSource(arr: any[]) {
    this.dataSource = Observable
      .create((observer: Observer<any>) => {
        observer.next(this.search);
      })
      .pipe(mergeMap((queryToken: string) => this.getAsObservable(arr, queryToken)));
  }

  private getAsObservable(arr: any[], queryToken: string) {
    return of(
      arr.filter((item: any) => {
        return item.name.indexOf(queryToken) > -1 || queryToken === '' || !queryToken;
      })
    );
  }
}
