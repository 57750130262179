<input type="text" disabled *ngIf="isReadonly" class="form-control" value="{{readonlyValue}}">
<div class="required-field-block" *ngIf="!isReadonly">
    <input [(ngModel)]="search"
           (ngModelChange)="autoSelectOnMatch($event)"
           [typeahead]="items"
           (typeaheadOnSelect)="typeaheadOnSelect($event)"
           (typeaheadNoResults)="typeaheadNoResults()"
           (typeaheadOnBlur)="checkError()"
           [typeaheadOptionsLimit]="10"
           [typeaheadMinLength]="0"
           [typeaheadItemTemplate]="template"
           typeaheadWaitMs="200"
           typeaheadOptionField="name"
           typeaheadScrollable="true"
           class="form-control"
           name="typeahead">

    <div class="required-icon">
        <div class="text">*</div>
    </div>
</div>
