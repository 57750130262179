import { Component, OnInit } from '@angular/core';
import { MeService } from '../services/me.service';
import { Me } from '../services/entities/me';

@Component({
  selector: 'yf-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public menuItems: any;
  private meService: MeService;
  private me: Me;

  constructor(meService: MeService) {
    this.meService = meService;
    this.menuItems = [
      {
        routerLink: '/installation',
        icon: 'fa-plus',
        title: 'home.new_installation',
        openForAll: true
      },
      {
        routerLink: '/move',
        icon: 'fa-long-arrow-right',
        title: 'home.move_dctu',
        openForAll: true
      },
      {
        routerLink: '/replace',
        icon: 'fa-retweet',
        title: 'home.replace_dctu',
        openForAll: true
      },
      {
        routerLink: '/remove',
        icon: 'fa-minus',
        title: 'home.remove_installation',
        openForAll: true
      },
      {
        routerLink: '/installation-sites',
        icon: 'fa-map-marker',
        title: 'home.installation_sites',
        openForAll: false
      },
      {
        routerLink: '/log',
        icon: 'fa-list-alt',
        title: 'home.log',
        openForAll: true
      }
    ];
  }

  public ngOnInit(): void {
    this.meService.getMe().subscribe((me) => {
      this.me = me;
    });
  }

  public isSuperAdmin(me: Me): boolean {
    if (!me) {
      return false;
    }
    return me.type === 'SUPER_ADMIN' || me.type === 'YF_SUPER_ADMIN';
  }
}
