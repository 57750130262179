import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InstallationLog } from './entities/installation-log';
import { Json } from './utils/json';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class LogService {
  private http: HttpClient;
  private url = '/api/log';
  private readonly installationLogs$: BehaviorSubject<InstallationLog[]> = new BehaviorSubject([]);

  constructor(http: HttpClient) {
    this.http = http;
  }

  get installationLogs(): Observable<InstallationLog[]> {
    return this.installationLogs$.asObservable();
  }

  public getInstallationLogs() {
    return this.http.get(this.url).pipe(
      map(Json.manyOf(InstallationLog)))
      .subscribe((installationLogs) => {
        this.installationLogs$.next(installationLogs);
      });
  }
}
