export class Dctu {
  public id: number;
  public name: string;
  public dctuType: number;

  constructor(values: { displayName: string, dctu: {} } = {displayName: '', dctu: {}}) {
    this.name = '';
    Object.assign(this, values.dctu);
    this.name = values.displayName;
  }
}
