import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable()
export class LoggedInService {
  public isLoggedIn = false;
  public preparingToLogout = false;

  constructor(private authService: AuthService, private router: Router ) {
    this.authService = authService;
    this.router = router;
  }

  public logout() {
    this.authService.logout().subscribe(() => {
      this.router.navigate(['/login']);
      this.isLoggedIn = false;
    });
  }
}
