import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from 'environments/environment';
import { ROUTING } from './app.routes';
import { defineLocale, PaginationModule, TooltipModule, TypeaheadModule } from 'ngx-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TagInputModule } from 'ngx-chips';

import { AppComponent } from './app.component';

import { ServiceModule } from './services/service.module';
import { GuardsModule } from './guards/guards.module';
import { TypeaheadComponent } from './components/typeahead/typeahead.component';
import { InstallationFormDctuDetailsComponent } from './components/installation-form/dctu-details/dctu-details.component';
import { InstallationFormFilesComponent } from './components/installation-form/files/files.component';
import { InstallationFormGeneralDetailsComponent } from './components/installation-form/general-details/general-details.component';
import { InstallationFormPlacementComponent } from './components/installation-form/placement/placement.component';

import '../styles/styles.scss';
import { translateFactory } from './app.translate';
import { AppInterceptor } from './app.interceptor';
import { HttpCache } from './app.cache';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { InstallationComponent } from './installation/installation.component';
import { InstallationSitesComponent } from './installation-sites/installation-sites.component';
import { LogComponent } from './log/log.component';
import { FilterAsyncPipe } from './pipes/filter/filter.pipe';
import { DatePipe } from '@angular/common';
import { MoveComponent } from './move/move.component';
import { RemoveComponent } from './remove/remove.component';
import { ReplaceComponent } from './replace/replace.component';

import { enGbLocale } from 'ngx-bootstrap/locale';
import { ClickStopPropagationDirective } from './directives/prevent-default/prevent-default.directive';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    InstallationComponent,
    InstallationFormDctuDetailsComponent,
    InstallationFormFilesComponent,
    InstallationFormGeneralDetailsComponent,
    InstallationFormPlacementComponent,
    InstallationSitesComponent,
    LogComponent,
    LoginComponent,
    MoveComponent,
    RemoveComponent,
    ReplaceComponent,
    TypeaheadComponent,

    ClickStopPropagationDirective,
    FilterAsyncPipe,
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (translateFactory),
        deps: [HttpClient]
      }
    }),
    ROUTING,
    TagInputModule,
    TypeaheadModule.forRoot(),
    TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),

    GuardsModule,
    ServiceModule,
  ],

  providers: [
    environment.ENV_PROVIDERS,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true
    },

    DatePipe,
    FilterAsyncPipe,
    HttpCache,
  ]
})
export class AppModule {
  constructor() {
    defineLocale('en-gb', enGbLocale);
  }
}
