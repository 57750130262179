import { Component, OnInit } from '@angular/core';
import { InstallationSiteService } from '../services/installation-site.service';
import { InstallationSite } from '../services/entities/installation-site';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'yf-installation-sites',
  templateUrl: './installation-sites.component.html',
  styleUrls: ['./installation-sites.component.scss']
})
export class InstallationSitesComponent implements OnInit {
  public installationSite: InstallationSite = new InstallationSite();
  public installationSites: Observable<InstallationSite[]>;

  private installationSiteService: InstallationSiteService;
  private translate: TranslateService;

  constructor(installationSiteService: InstallationSiteService, translate: TranslateService) {
    this.installationSiteService = installationSiteService;
    this.installationSites = this.installationSiteService.installationSites;
    this.translate = translate;
  };

  public ngOnInit() {
    this.installationSiteService.getInstallationSites();
  }

  public deleteSite(installationSite: InstallationSite) {
    let response = confirm(this.translate.instant('installation_sites.confirmation'));
    if (response) {
      this.installationSiteService.deleteInstallationSite(installationSite);
    }
  };

  public create(installationSite: InstallationSite) {
    this.installationSiteService.createInstallationSite(installationSite);
    this.installationSite = new InstallationSite();
  }
}
