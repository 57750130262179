import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable, empty, of, throwError, concat } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpCache } from './app.cache';
import { Router } from '@angular/router';
import { LoggedInService } from './services/logged-in.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  private cache: HttpCache;
  private router: Router;
  private loggedInService: LoggedInService;

  constructor(cache: HttpCache, router: Router, loggedInService: LoggedInService) {
    this.cache = cache;
    this.router = router;
    this.loggedInService = loggedInService;
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authReq = req.clone({withCredentials: true});

    if (req.method !== 'GET') {
      return next.handle(authReq).pipe(catchError((err) => {
        return this.checkAuthorization(err);
      }));
    }

    let maybeCachedResponse: Observable<HttpEvent<any>> = empty();
    const cachedResponse = this.cache.get(req);

    if (cachedResponse) {
      maybeCachedResponse = of(cachedResponse);
    }

    const networkResponse = next.handle(authReq).pipe(tap((event) => {
      if (event instanceof HttpResponse) {
        this.cache.put(req, event);
      }
    }),
      catchError((err) => {
        return this.checkAuthorization(err);
    }));

    return concat(maybeCachedResponse, networkResponse);
  }

  private checkAuthorization(err) {
    if (err.status === 401) {
      this.loggedInService.isLoggedIn = false;
      this.router.navigate(['/login']);
    }

    return throwError(err);
  }
}
