import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

export const DEFAULT_LANGUAGE = 'en-GB';
export const AVAILABLE_LANGUAGES: string[] = ['sv', 'en-GB'];

export function translateFactory(http: HttpClient): TranslateLoader {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
