<h2>{{'home.log' | translate}}</h2>
<div class="panel panel-default" id="files">
  <div class="panel-heading">
    {{'log.search' | translate}}
  </div>
  <div class="panel-body">
    <tag-input [(ngModel)]="tags"
               (onAdd)="addRemoveTag()"
               (onRemove)="addRemoveTag()"
               [addOnBlur]="true"
               [clearOnBlur]="true"
               [ripple]="false"
               [identifyBy]="'value'"
               [displayBy]="'display'"
               [secondaryPlaceholder]="'log.searchPlaceholder' | translate"
               data-cy="logSearchInput">
      <tag-input-dropdown [autocompleteItems]="tagItems"
                          [limitItemsTo]="20">
      </tag-input-dropdown>
    </tag-input>
  </div>
</div>

<table class="table table-bordered table-responsive table-striped table-hover table-condensed">
  <thead>
    <tr>
      <th>{{'log.asset' | translate}}</th>
      <th>{{'log.dctu' | translate}}</th>
      <th>{{'log.action' | translate}}</th>
      <th class="fixed-width">{{'log.installer' | translate}}</th>
      <th class="fixed-width">{{'log.installationSite' | translate}}</th>
      <th>{{'log.installationDate' | translate}}</th>
      <th>{{'log.created' | translate}}</th>
      <th>{{'log.hasAttachment' | translate}}</th>
      <th>{{'log.status' | translate}} <i class="fa fa-question-circle" tooltip="Here you can see the status of each registered action." placement="top"></i></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of (installationLogs | async | yfFilterAsyncPipe: tags) | slice:((currentPage-1)*maxItemsPerPage):(currentPage*maxItemsPerPage)"
        [routerLink]="['/log/' + item.actionKey.toLowerCase() + '/' + item.installationTaskId]" data-cy="logTableRow">
      <td class="pointer">{{item.targetAsset}}</td>
      <td class="pointer">{{item.targetDctu}}</td>
      <td class="pointer">{{item.actionKey}}</td>
      <td class="pointer"><div class="fixed-width" title="{{item.user}}">{{item.user}}</div></td>
      <td class="pointer"><div class="fixed-width" title="{{item.installationSite}}">{{item.installationSite}}</div></td>
      <td class="pointer">{{item.installationDate | date:'yyyy-MM-dd'}}</td>
      <td class="pointer" data-cy="logTableRowCreated">{{item.timestamp | date:'yyyy-MM-dd HH:mm'}}</td>
      <td class="pointer text-center" data-cy="logTableRowHasAttachment"><i class="fa fa-paperclip" *ngIf="item.hasAttachment"></i></td>
      <td class="action-cell" data-cy="logTableRowStatus">
        <i class="fa"
           [ngClass]="{'fa-check green': item.completed && !item.rejected, 'fa-exclamation-triangle orange': !item.completed && !item.rejected, 'fa-times red': !item.completed && item.rejected}"
           title="{{getTitleFor(item)}}">

        </i>
        <button *ngIf="isYFAdmin(me) && (!item.completed && !item.rejected)"
                class="btn btn-success btn-xs pull-right buffer-left"
                yfClickStopPropagation
                (click)="markAsCompleted(item.installationTaskId)">
          <i class="fa fa-check"></i>
        </button>
        <button *ngIf="isYFAdmin(me) && (!item.completed && !item.rejected)"
                class="btn btn-danger btn-xs pull-right"
                yfClickStopPropagation
                (click)="markAsRejected(item.installationTaskId)">
          <i class="fa fa-times"></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>
<div class="text-center">
  <pagination [boundaryLinks]="true" [totalItems]="(installationLogs | async | yfFilterAsyncPipe: tags).length" [(ngModel)]="currentPage" class="pagination-sm" [itemsPerPage]="maxItemsPerPage" [maxSize]="10"
              previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"></pagination>
</div>
