<div class="form-group">
    <label class="input-label" for="files">{{'installation.files.label' | translate}}</label>
    <i class="fa fa-question-circle" tooltip="Select the file(s) you might want to attach, e.g. image or scanned document. Accepted file types are: .txt, .xls, .doc, .docx, .pdf, .xlsx, .jpg, .jpeg, .png and .gif" placement="right"></i>
    <div class="panel panel-default"
         id="files">
        <div class="panel-heading">
            <input class="inputfile"
                   type="file"
                   name="file"
                   id="file"
                   accept=".txt,.xls,.doc,.docx,.pdf,.xlsx,.jpg,.jpeg,.png,.gif"
                   multiple
                   (change)="updateFiles($event)"
                   data-cy="fileInput"/>

            <label for="file">
                <i class="fa fa-upload" aria-hidden="true"></i> {{'installation.add_files' | translate}}
                <strong class="pull-right" data-cy="availableFileSpace">
                  {{convertToMb(availableFileSpace) + ' MB left'}}
                </strong>
            </label>
        </div>

        <div class="panel-body no-padding-bottom" [hidden]="files.length === 0">
            <table class="table" data-cy="fileTable">
                <thead>
                <tr>
                    <th class="width-75">{{'installation.files.name' | translate}}</th>
                    <th>{{'installation.files.size' | translate}}</th>
                    <th class="width-5"></th>
                </tr>
                </thead>
                <tbody>

                <tr *ngFor="let file of files;">
                    <td>{{file.name}}</td>
                    <td data-cy="fileSize">{{ file.size/1024/1024 | number:'.2' }} MB</td>
                    <td class="text-center">
                        <i class="fa fa-trash remove-file" (click)="removeFile(file)" data-cy="removeFile"></i>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="panel-body">
      <table class="table">
        <tbody>
          <tr *ngFor="let error of errorList; let errorIndex = index" class="list-style-none" data-cy="fileErrorList">
            <td class="width-95">
              <strong class="text-break">
                  {{error.fileName}}
              </strong>
              <label class="error-message">
                {{error.errorMessage}}
              </label>
            </td>
            <td class="text-center width-5">
              <i class="fa fa-times remove-file" (click)="removeErrorMessage(errorIndex)"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
</div>
