import { Component, ViewEncapsulation } from '@angular/core';
import { AuthService } from './services/auth.service';
import { LoggedInService } from './services/logged-in.service';
import { MeService } from './services/me.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from './app.translate';

export const ROOT_SELECTOR = 'yf-app';

@Component({
  selector: ROOT_SELECTOR,
  encapsulation: ViewEncapsulation.None,
  templateUrl: './app.component.html'
})
export class AppComponent {
  private authService: AuthService;
  private loggedInService: LoggedInService;
  private meService: MeService;
  private router: Router;
  private translateService: TranslateService;

  constructor(authService: AuthService, loggedInService: LoggedInService,
              meService: MeService, router: Router, translateService: TranslateService) {
    this.authService = authService;
    this.loggedInService = loggedInService;
    this.meService = meService;
    this.router = router;
    this.translateService = translateService;

    this.setTranslationProperties();
    this.getMe();
  }

  private setTranslationProperties() {
    this.translateService.addLangs(AVAILABLE_LANGUAGES);
    this.translateService.setDefaultLang(DEFAULT_LANGUAGE);
    this.translateService.use(DEFAULT_LANGUAGE);
  }

  private getMe() {
    this.meService.getMe().subscribe(() => {
      this.loggedInService.isLoggedIn = true;
    });
  }
}
