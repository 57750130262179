import { Dctu } from './dctu';
import { Asset } from './asset';
import { InstallationSite } from './installation-site';

export class InstallationTask {
  public asset: Asset;
  public comment: string;
  public connectedToPower: boolean;
  public created: string;
  public dctu: Dctu;
  public files: Array<{ id: number, name: string, size: string, data: string, user: string, created: Date }>;
  public installationDate: string;
  public installationSite: InstallationSite;
  public installer: string;
  public imageMarkerPositionX: number;
  public imageMarkerPositionY: number;
  public sensors: string[];
  public taskType: string;
  public reporter: string;

  constructor(values: Object = {}) {
    this.sensors = [];
    this.files = [];
    this.asset = new Asset();
    this.dctu = new Dctu();
    Object.assign(this, values);
  }
}
