import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoggedInService } from '../../services/logged-in.service';

@Injectable()
export class LoginGuard implements CanActivate {
  private loggedInService: LoggedInService;
  private router: Router;

  constructor(loggedInService: LoggedInService, router: Router) {
    this.loggedInService = loggedInService;
    this.router = router;
  }

  public canActivate(): boolean {
    return !this.loggedInService.isLoggedIn;
  }
}
