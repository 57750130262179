<div class="main">
  <yf-header></yf-header>

  <div class="container">
    <div class="row">
      <main class="col-xs-12">
        <router-outlet></router-outlet>
      </main>
    </div>
  </div>

  <footer class="text-right">
    <img class="branded-image" src="assets/images/FullColor_trimmed_300x66.png" alt="fm-ball">
  </footer>
</div>
