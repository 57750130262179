import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoggedInService } from '../../services/logged-in.service';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class HasComponentChangedGuard implements CanDeactivate<ComponentCanDeactivate> {

  constructor(private loggedInService: LoggedInService) {
    this.loggedInService = loggedInService;
  }

  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    return component.canDeactivate() ?
      true :
      // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
      // when navigating away from your angular app and canDeactivate() function is annotated with @HostListener('window:beforeunload'),
      // the browser will show a generic warning message
      // see http://stackoverflow.com/a/42207299/7307355
      this.handle();
  }

  handle() {
    const message = 'There are unsaved changes to this page, do you want to leave without saving these changes?'
    if (confirm(message)) {
      if (this.loggedInService.preparingToLogout) {
        this.loggedInService.logout();
      }
      return true;
    }
    this.loggedInService.preparingToLogout = false;
    window.scrollTo(0,document.body.scrollHeight);
    return false;
  }
}
