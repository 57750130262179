<div class="form-group row no-margin">
    <div class="col-md-4">
        <label class="input-label" for="hasPower">{{'installation.dctu_connected_to_power' | translate}}</label>
        <i class="fa fa-question-circle" tooltip="Check this box if the DCTU has been connected to external power, e.g. in a locomotive." placement="bottom"></i>
        <div class="panel panel-default no-margin"
             id="hasPower">
            <div class="panel-heading"
                 [ngClass]="{'disabled': formDisabled || isReadonly}"
                 (click)="toggleConnectedToPower()">

                <i class="fa constant-width"
                   [ngClass]="{'fa-check-square-o': connectedToPower, 'fa-square-o': !connectedToPower}"
                   aria-hidden="true"></i>

                {{'installation.dctu_connected_to_power' | translate}}
            </div>
        </div>
    </div>

    <div class="col-md-8">
        <label class="input-label" for="sensorsIntalled">{{'installation.sensor_installed' | translate}}</label>
        <i class="fa fa-question-circle" tooltip="Check this box if external sensor(s) have been installed and connected to the DCTU." placement="right"></i>
        <div class="panel panel-default"
             id="sensorsIntalled">
            <div class="panel-heading"
                 [ngClass]="{'disabled': formDisabled || isReadonly}"
                 (click)="toggleSensorsInstalled()">

                <i class="fa constant-width"
                   [ngClass]="{'fa-check-square-o': isSensorInstalled(), 'fa-square-o': !isSensorInstalled()}"
                   aria-hidden="true"></i>

                {{'installation.sensor_installed' | translate}}
            </div>

            <div class="panel-body no-padding-bottom" [ngClass]="{'disabled': isReadonly}" [hidden]="!isSensorInstalled()" *ngIf="isSensorInstalled()">
                <div class="form-group row">
                    <div class="col-md-9">
                        <div [ngClass]="{'has-error' : numberOfSensorsInstalled < MIN_SENSORS || numberOfSensorsInstalled > MAX_SENSORS}">
                            <label for="numberOfSensorsInstalled">{{'installation.number_of_sensors' | translate}}</label>
                            <input type="number"
                                   name="numberOfSensorsInstalled"
                                   class="form-control"
                                   id="numberOfSensorsInstalled"
                                   [(ngModel)]="numberOfSensorsInstalled"
                                   [min]="MIN_SENSORS"
                                   [max]="MAX_SENSORS"
                                   [disabled]="isReadonly">
                        </div>
                    </div>

                    <div class="col-md-3">
                        <label for="apply" class="apply" [ngClass]="{'disabled': isReadonly}">{{'generic.apply' | translate}}</label>
                        <div id="apply">
                            <button class="btn btn-primary btn-block" type="button" (click)="updateNumberOfSensorsForm()" [disabled]="isReadonly">
                                {{'generic.apply' | translate}}
                            </button>
                        </div>
                    </div>
                </div>

                <div class="form-group row" style="margin-bottom: 0"
                     *ngIf="isSensorInstalled() && sensors.length > 0">
                    <div class="form-group col-md-4"
                         *ngFor="let sensor of sensors;let index = index;">
                        <label>Sensor {{index + 1}}</label>
                        <input type="text" [ngModel]="sensors[index]" class="form-control"
                               name="sensor" disabled>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
