import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { InstallationSiteService } from '../../../services/installation-site.service';
import { InstallerService } from '../../../services/installer.service';
import { InstallationSite } from '../../../services/entities/installation-site';
import { Installer } from '../../../services/entities/installer';
import { InstallationTask } from '../../../services/entities/installation-task';
import { TypeaheadInstaller } from '../../../entities/typeahead-installer';

@Component({
  selector: 'yf-installation-form-general-details',
  templateUrl: './general-details.component.html',
  styleUrls: ['./general-details.component.scss']
})
export class InstallationFormGeneralDetailsComponent implements OnInit, OnChanges {
  @Output() public onInstallerError = new EventEmitter<boolean>();
  @Output() public onInstallationSiteError = new EventEmitter<boolean>();
  @Output() public onInstallationDateError = new EventEmitter<boolean>();
  @Output() public installationTaskValue: InstallationTask;

  @Input() public isReadonly: boolean;
  @Input() public removeMode = false;

  @Input()
  set installationTask(val) {
    this.installationTaskValue = val;
    this.installationTaskChange.emit(this.installationTaskValue);
  }

  get installationTask() {
    return this.installationTaskValue;
  }

  public installationSites: InstallationSite[] = [];
  public typeaheadInstallers: TypeaheadInstaller[] = [];
  public maxDate: Date = new Date();

  public installationSiteError: boolean;
  public installerError: boolean;
  public installationDateError: boolean;

  private installationSiteService: InstallationSiteService;
  private installerService: InstallerService;

  private bsConfig;
  private installers: Installer[] = [];
  private readonlyInstallerName: string;

  private installationTaskChange = new EventEmitter<InstallationTask>();

  constructor(installationSiteService: InstallationSiteService, installerService: InstallerService) {
    this.installationSiteService = installationSiteService;
    this.installerService = installerService;

    this.bsConfig = Object.assign({}, {
      dateInputFormat: 'YYYY-MM-DD',
      containerClass: 'theme-orange',
      locale: 'en-gb',
      customTodayClass: 'highlight-today-date'
    });
  };

  public ngOnInit(): void {
    this.getInstallationSites();
    this.getInstallers();
  }

  public ngOnChanges(changes: any): void {
    if (this.hasInstallationTaskChanged(changes)) {
      this.readonlyInstallerName = this.installationTask.installer;
    }
  }

  public getInstallerNameFor(installerId: number): string {
    // TODO: what if installer is removed?
    return this.installers.filter((installer) => {
      return installer.id === installerId;
    }).map((installer) => {
      return `${installer.name}, ${installer.company || 'N/A'}`;
    })[0];
  }

  public setInstallerError(error: boolean) {
    this.installerError = error;
    this.onInstallerError.emit(error);
  }

  public setInstallationSiteError(error: boolean) {
    this.installationSiteError = error;
    this.onInstallationSiteError.emit(error);
  }

  public setInstallationDateError(error: boolean) {
    this.installationDateError = error;
    this.onInstallationDateError.emit(error);
  }

  public setInstallationDateErrorWhenFocus() {
    if (!this.installationTask.installationDate) {
      this.setInstallationDateError(true);
    }
  }

  public setInstallationDate(date: string) {
    if (date) {
      this.setInstallationDateError(false);
      this.installationTask.installationDate = date;
    } else {
      this.setInstallationDateError(true);
    }
  }

  private getInstallationSites() {
    this.installationSiteService.oldGetInstallationSites().subscribe((installationSites: InstallationSite[]) => {
      this.installationSites = installationSites;
    }, () => this.setInstallationSiteError(true));
  }

  private getInstallers() {
    this.installerService.getInstallers()
      .subscribe((installers: Installer[]) => {
        this.installers = installers;
        this.mapToTypeaheadInstallers();
      }, () => this.setInstallerError(true));
  }

  private mapToTypeaheadInstallers() {
    this.typeaheadInstallers = [];
    this.installers.map((installer: Installer) => {
      this.typeaheadInstallers.push(new TypeaheadInstaller(
        `${installer.name}, ${installer.company || 'N/A'}`,
        installer
      ));
    });
  }

  private hasInstallationTaskChanged(changes: any): boolean {
    return changes.installationTask && changes.installationTask.currentValue && changes.installationTask.currentValue.installerId;
  }
}
