import { NgModule } from '@angular/core';
import { AppGuard } from './app/app.guard';
import { LoginGuard } from './login/login.guard';
import { HasComponentChangedGuard } from './app/hasComponentChanged.guard';

@NgModule({
  providers: [
    AppGuard,
    LoginGuard,
    HasComponentChangedGuard
  ]
})
export class GuardsModule {}
