import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Json } from './utils/json';
import { InstallationSite } from './entities/installation-site';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class InstallationSiteService {
  private http: HttpClient;
  private url = '/api/installation-site';
  private readonly installationSites$: BehaviorSubject<InstallationSite[]> = new BehaviorSubject([]);

  constructor(http: HttpClient) {
    this.http = http;
  }

  get installationSites(): Observable<InstallationSite[]> {
    return this.installationSites$.asObservable();
  }

  public getInstallationSites() {
    this.http.get(this.url).pipe(
      map(Json.manyOf(InstallationSite)))
      .subscribe((installationSites) => {
        this.installationSites$.next(installationSites);
      });
  }

  public oldGetInstallationSites() {
    return this.http.get(this.url).pipe(
      map(Json.manyOf(InstallationSite)));
  }

  public createInstallationSite(installationSite: InstallationSite) {
    this.http.post(this.url, installationSite).subscribe(() => {
      this.getInstallationSites();
    });
  }

  public deleteInstallationSite(installationSite: InstallationSite) {
    this.http.delete(`${this.url}/${installationSite.id}`).subscribe(() => {
      this.getInstallationSites();
    });
  }
}
