import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InstallationTask } from './entities/installation-task';
import { Observable } from 'rxjs';

@Injectable()
export class InstallationTaskService {
  private http: HttpClient;
  private url = '/api/installation-task';

  constructor(http: HttpClient) {
    this.http = http;
  }

  public createInstallationTask(installationTask: InstallationTask): Observable<any> {
    return this.http.post(this.url, installationTask);
  }

  public editInstallationTask(installationTaskId: number, installationTask: InstallationTask): Observable<any> {
    return this.http.put(`${this.url}/${installationTaskId}`, installationTask);
  }

  public markAsComplete(installationTaskId: number): Observable<any> {
    return this.http.post(`${this.url}/${installationTaskId}/complete`, {});
  }

  public markAsRejected(installationTaskId: number): Observable<any> {
    return this.http.post(`${this.url}/${installationTaskId}/reject`, {});
  }

  public get(installationTaskId: number): Observable<InstallationTask> {
    return this.http.get<InstallationTask>(`${this.url}/${installationTaskId}`);
  }
}
