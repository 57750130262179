<div class="overlay">
    <main class="container">
        <div class="row vertical-center">
            <div class="col-md-4 text-center login-box">
                <div class="welcome">
                    <img class="image" src="./assets/images/test_animated.svg" />
                </div>

                <div class="form-group">
                    <div class="input-group">
                        <span class="input-group-addon"><i class="fa fa-user" aria-hidden="true"></i></span>
                        <input type="text" class="form-control" id="username" placeholder="Username" [(ngModel)]="username" data-cy="loginUsername">
                    </div>
                </div>

                <div class="form-group">
                    <div class="input-group">
                        <span class="input-group-addon"><i class="fa fa-unlock-alt" aria-hidden="true"></i></span>
                        <input type="password" class="form-control" id="password" placeholder="Password" [(ngModel)]="password" (keyup.enter)="login()" data-cy="loginPassword">
                    </div>
                </div>
                <a *ngIf="ssoEnv" href="/api/auth/sso" class="btn btn-primary mol-red pull-left">Login</a>
                <button class="btn btn-primary pull-right" [ngClass]="{'btn-block': !ssoEnv}" (click)="login()" data-cy="loginSubmit">Login</button>
            </div>
        </div>
    </main>
</div>
