import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuthService {
  public url = '/api/auth';
  private http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  public login(username: string, password: string): Observable<any> {
    return this.http.post(this.url, {username, password});
  }

  public logout() {
    return this.http.get(this.url);
  }
}
