import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { InstallationComponent } from './installation/installation.component';
import { InstallationSitesComponent } from './installation-sites/installation-sites.component';
import { LogComponent } from './log/log.component';
import { MoveComponent } from './move/move.component';
import { RemoveComponent } from './remove/remove.component';
import { ReplaceComponent } from './replace/replace.component';
import { AppGuard } from './guards/app/app.guard';
import { LoginGuard } from './guards/login/login.guard';
import { HasComponentChangedGuard } from './guards/app/hasComponentChanged.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AppGuard],
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'installation',
        component: InstallationComponent
      },
      {
        path: 'installation/:id',
        component: InstallationComponent
      },
      {
        path: 'log/install/:id',
        canDeactivate: [HasComponentChangedGuard],
        component: InstallationComponent
      },
      {
        path: 'move',
        component: MoveComponent
      },
      {
        path: 'log/move/:id',
        canDeactivate: [HasComponentChangedGuard],
        component: MoveComponent
      },
      {
        path: 'replace',
        component: ReplaceComponent
      },
      {
        path: 'log/replace/:id',
        canDeactivate: [HasComponentChangedGuard],
        component: ReplaceComponent
      },
      {
        path: 'remove',
        component: RemoveComponent
      },
      {
        path: 'log/remove/:id',
        canDeactivate: [HasComponentChangedGuard],
        component: RemoveComponent
      },
      {
        path: 'installation-sites',
        component: InstallationSitesComponent
      },
      {
        path: 'log',
        component: LogComponent
      },
    ]
  },
  {
    path: 'login',
    canActivate: [LoginGuard],
    component: LoginComponent
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: ''
  }
];

export const ROUTING = RouterModule.forRoot(routes, {useHash: true});
