import { Component, OnInit } from '@angular/core';
import { InstallationTaskService } from '../services/installation-task.service';
import { InstallationTask } from '../services/entities/installation-task';
import { AssetService } from '../services/asset.service';
import { DctuService } from '../services/dctu.service';
import { Asset } from '../services/entities/asset';
import { Dctu } from '../services/entities/dctu';
import { DomSanitizer } from '@angular/platform-browser';
import { TASK_TYPES } from '../entities/task.types';
import { ActivatedRoute, Router } from '@angular/router';
import { ACCEPTED_IMAGE_ENDINGS } from '../app.endings';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from '../guards/app/hasComponentChanged.guard';
import { HasFilesChangedService } from '../services/hasFilesChanged.service';

@Component({
  selector: 'yf-replace',
  templateUrl: './replace.component.html',
  styleUrls: ['./replace.component.scss']
})
export class ReplaceComponent implements OnInit, ComponentCanDeactivate {
  public assets: Asset[] = [];
  public dctus: Dctu[] = [];
  public assetError: boolean;
  public dctuError: boolean;
  public loading = false;
  public installerError: boolean;
  public installationSiteError: boolean;
  public installationDateError: boolean;
  public images: Array<{ id: number, data: string }> = [];
  public installationTask: InstallationTask;
  public loadedTask = false;
  public submitting = false;
  public taskId: number;

  private sanitizer: DomSanitizer;
  private installationTaskService: InstallationTaskService;
  private assetService: AssetService;
  private dctuService: DctuService;
  private hasFilesChangedService: HasFilesChangedService;
  private router: Router;
  private route: ActivatedRoute;

  constructor(installationTaskService: InstallationTaskService,
              assetService: AssetService,
              dctuService: DctuService,
              sanitizer: DomSanitizer,
              router: Router,
              route: ActivatedRoute,
              hasFilesChangedService: HasFilesChangedService) {
    this.assetService = assetService;
    this.dctuService = dctuService;
    this.sanitizer = sanitizer;
    this.installationTaskService = installationTaskService;
    this.hasFilesChangedService = hasFilesChangedService;
    this.installationTask = new InstallationTask();
    this.route = route;
    this.router = router;

    this.installationTask = new InstallationTask();
  };

  public async ngOnInit() {
    this.loading = true;
    this.getAssets();
    this.getDctus();
    const id = this.route.snapshot.params['id'];
    if (id) {
      this.taskId = id;
      await this.installationTaskService.get(id).toPromise()
        .then((task => {
            this.installationTask = task;
            this.images = this.getImagesFromGeneralFileList(task.files);
            this.loadedTask = true;
          })
        );
    } else {
      this.installationTask.taskType = TASK_TYPES.REPLACE;
    }
    setTimeout(() => (this.loading = false), 1000);
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.submitting) {
      this.submitting = false;
      return true;
    }
    return !this.hasFilesChangedService.hasFilesChanged;
  }

  getImagesFromGeneralFileList(fileList) {
    return fileList
      .filter((file) => ACCEPTED_IMAGE_ENDINGS.test(file.name))
      .map((file) => ({data: file.data}));
  }

  public invalidForm() {
    return this.assetError ||
      this.dctuError ||
      this.installerError ||
      this.installationSiteError ||
      this.installationDateError ||
      this.installationTask.asset.name === '' ||
      !this.installationTask.installer ||
      this.installationTask.dctu.name === '' ||
      !this.installationTask.installationDate ||
      this.installationTask.imageMarkerPositionX === undefined ||
      this.installationTask.imageMarkerPositionY === undefined;
  }

  public createInstallationTask() {
    if (!this.invalidForm()) {
      this.submitting = true;
      this.installationTaskService.createInstallationTask(this.installationTask).subscribe(() => {
        this.submitting = false;
        this.router.navigate(['/log']);
      });
    }
  }

  public editSubmit() {
    if (!this.invalidForm()) {
      this.submitting = true;
      this.installationTaskService.editInstallationTask(this.taskId, this.installationTask).subscribe(() => {
        this.router.navigate(['/log']);
      });
    }
  }

  public sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  public setDctuOn(task: InstallationTask, event) {
    task.dctu = event;
    task.connectedToPower = false;
    task.sensors = [];
  }

  private getAssets() {
    this.assetService.getAssets(false)
      .subscribe((assets: Asset[]) => {
        this.assets = assets;
      }, () => this.assetError = true);
  }

  private getDctus() {
    this.dctuService.getDctus()
      .subscribe((dctus: Dctu[]) => {
        this.dctus = dctus;
      }, () => this.dctuError = true);
  }
}
