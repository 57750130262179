import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoggedInService } from '../../services/logged-in.service';

@Injectable()
export class AppGuard implements CanActivate {
  private loggedInService: LoggedInService;
  private router: Router;

  constructor(loggedInService: LoggedInService, router: Router) {
    this.loggedInService = loggedInService;
    this.router = router;
  }

  public canActivate(): boolean {
    return this.checkLogin();
  }

  private checkLogin(): boolean {
    if (this.loggedInService.isLoggedIn) {
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }
}
