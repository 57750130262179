import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Dctu } from '../../../services/entities/dctu';
import { TranslateService } from '@ngx-translate/core';
import { DCTU_TYPES } from '../../../entities/dctu.types';

@Component({
  selector: 'yf-installation-form-dctu-details',
  templateUrl: './dctu-details.component.html',
  styleUrls: ['./dctu-details.component.scss']
})
export class InstallationFormDctuDetailsComponent implements OnChanges {
  @Input() public isReadonly: boolean;
  @Input() public dctu: Dctu;
  @Input() public connectedToPower: boolean;
  @Input() public sensors: string[];

  @Output() public onToggleConnectedToPower = new EventEmitter<boolean>();
  @Output() public onSetSensors = new EventEmitter<string[]>();

  public formDisabled: boolean;

  private readonly MIN_SENSORS = 1;
  private readonly MAX_SENSORS = 6;

  private numberOfSensorsInstalled: number;
  private translate: TranslateService;

  constructor(translate: TranslateService) {
    this.formDisabled = true;
    this.numberOfSensorsInstalled = 1;
    this.translate = translate;
  }

  public ngOnChanges(changes: any): void {
    if (this.hasDctuChanged(changes)) {
      this.formDisabled = !this.dctuCanBeConnectedToPower(this.dctu);
    }

    if (this.hasSensorsChanged(changes)) {
      if (this.sensors.length !== 0) {
        this.numberOfSensorsInstalled = this.sensors.length;
      }
    }
  }

  public toggleSensorsInstalled() {
    if (!this.formDisabled) {
      if (this.isSensorInstalled()) {
        this.onSetSensors.emit([]);
      } else {
        this.updateNumberOfSensorsForm();
      }
    }
  }

  public toggleConnectedToPower() {
    if (this.dctuCanBeConnectedToPower(this.dctu) && !this.formDisabled) {
      this.connectedToPower = !this.connectedToPower;
      this.onToggleConnectedToPower.emit(this.connectedToPower);
    }
  }

  public isSensorInstalled() {
    return this.sensors.length > 0;
  }

  private hasDctuChanged(changes: any) {
    return changes.dctu && changes.dctu.currentValue;
  }

  private hasSensorsChanged(changes: any) {
    return changes.sensors && changes.sensors.currentValue;
  }

  private dctuCanBeConnectedToPower(dctu: Dctu) {
    return dctu && dctu.dctuType && dctu.dctuType === DCTU_TYPES.IO;
  }

  private updateNumberOfSensorsForm() {
    this.sensors = [];
    if (this.validNumberOfSensors()) {
      for (let i = 0; i < this.numberOfSensorsInstalled; i++) {
        this.sensors.push(this.translate.instant('installation.temperature'));
      }
    }
    this.onSetSensors.emit(this.sensors);
  }

  private validNumberOfSensors() {
    return this.numberOfSensorsInstalled >= this.MIN_SENSORS && this.numberOfSensorsInstalled <= this.MAX_SENSORS;
  }
}
