import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ACCEPTED_FILE_ENDINGS, ACCEPTED_IMAGE_ENDINGS } from '../../../app.endings';
import { Me } from '../../../services/entities/me';
import { MeService } from '../../../services/me.service';
import { HasFilesChangedService } from '../../../services/hasFilesChanged.service';

@Component({
  selector: 'yf-installation-form-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class InstallationFormFilesComponent implements OnInit {
  @Output() public filesChange = new EventEmitter<any>();

  @Input()
  get files() {
    return this.filesList;
  }

  set files(val) {
    this.filesList = val;
    this.filesChange.emit(this.filesList);
  }

  private filesList = [];
  private me: Me;
  private meService: MeService;
  private hasFilesChangedService: HasFilesChangedService;
  private totalAllowedFileSpaceIs7mb = 7 * 1024 * 1024;
  private maxAllowedFileSizeIs2mb = 2 * 1024 * 1024;
  public availableFileSpace = this.totalAllowedFileSpaceIs7mb;
  public errorList = [];
  private originalInstallationTaskFiles = [];

  constructor(meService: MeService, hasFilesChangedService: HasFilesChangedService) {
    this.meService = meService;
    this.meService.getMe().subscribe((me) => {
      this.me = me;
    });
    this.hasFilesChangedService = hasFilesChangedService;
  }

  public ngOnInit() {
    this.hasFilesChangedService.hasFilesChanged = false;
    this.originalInstallationTaskFiles = [...this.filesList];
    this.availableFileSpace -= this.files.reduce((a, b) => a + (Number(b.size) || 0), 0) ;
  }

  public async updateFiles (event) {
    const uploadedFiles = event.target.files;
    this.errorList = [];

    for (const file of uploadedFiles) {
      if (file.size > this.maxAllowedFileSizeIs2mb) {
        this.errorList.push({fileName: file.name, errorMessage: 'file is too large, maximum size is 2 MB'});
        continue;
      }
      const usedFileSpace = this.availableFileSpace - file.size;
      if (usedFileSpace < 0) {
        this.errorList.push({fileName: file.name, errorMessage: 'file exceeds the available storage limit'});
        continue;
      }
      this.availableFileSpace = usedFileSpace;

      const evt: any = await this.readFile(file);
      const fileIndex = this.uuidv4();
      if (ACCEPTED_FILE_ENDINGS.test(file.name) || ACCEPTED_IMAGE_ENDINGS.test(file.name)) {
        this.filesList.push({
          id: fileIndex,
          name: file.name,
          size: file.size,
          data: evt.currentTarget.result,
          created: new Date().toLocaleString('sv-SE'),
          user: this.me.name
        });
      }
      this.files = this.filesList;
    }
    this.hasFilesChangedService.hasFilesChanged = this.hasFilesChanged();
    event.target.value = '';
  }

  private readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (evt: any) => resolve(evt);
      reader.readAsDataURL(file);
    })
  }

  public convertToMb(fileSpace: number) {
    return Number((fileSpace / 1024 / 1024).toFixed(2));
  }

  public removeErrorMessage(index: number) {
    this.errorList.splice(index, 1);
  }

  public removeFile(fileToRemove: any) {
    this.errorList = [];
    this.availableFileSpace += Number(fileToRemove.size);

    this.files = this.filesList.filter((file) => {
      if (fileToRemove.id) {
        return file.id !== fileToRemove.id;
      }
      return file.data !== fileToRemove.data;
    });

    this.hasFilesChangedService.hasFilesChanged = this.hasFilesChanged();
  }

  // https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
  private uuidv4() {
    return ([1e7] as any + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      // tslint:disable-next-line:no-bitwise
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  private hasFilesChanged() {
    const originalInstallationTaskFiles = this.originalInstallationTaskFiles ?? [];
    const filesList = this.filesList ?? [];

    const oldFileIds = originalInstallationTaskFiles.map(file => file.id);
    const newFileIds = filesList.map(file => file.id);
    if(oldFileIds.length !== newFileIds.length){
      return true;
    }
    return !newFileIds.every((newId) => oldFileIds.includes(newId));
  }
}
