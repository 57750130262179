import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Tag } from '../../services/entities/tag';

@Pipe({
  name: 'yfFilterAsyncPipe'
})
export class FilterAsyncPipe implements PipeTransform {

  private readonly dateProperties = ['installationDate', 'timestamp'];
  private readonly vagueSearchProperties = ['targetAsset', 'targetDctu', 'actionKey', 'user', 'installationSite', 'installationDate', 'timestamp'];

  private datePipe: DatePipe;

  constructor(datePipe: DatePipe) {
    this.datePipe = datePipe;
  }

  public transform(data$: any[], what: any[]) {
    if (!what || what.length < 1) {
      return data$;
    }
    if (!data$) {
      return undefined;
    }
    return data$.filter((entry) => {
      return what.every((phrase: Tag) => {
        if (phrase.property.match('vagueSearch')) {
          return this.vaguePropertySearch(phrase, entry);
        } else {
          return this.strictPropertySearch(phrase, entry);
        }
      });
    });
  }

  private strictPropertySearch(phrase: Tag, entry) {
    if (this.dateProperties.indexOf(phrase.property) > -1) {
      return (phrase.property + '|').toUpperCase() +
        this.datePipe.transform(entry[phrase.property], 'yyyy-MM-dd') === (phrase.value.toUpperCase());
    } else {
      return (phrase.property + '|' + entry[phrase.property]).toUpperCase().trim() === phrase.value.toUpperCase().trim();
    }
  }

  private vaguePropertySearch(phrase: Tag, entry) {
    return this.vagueSearchProperties.some((property) => {
      if (this.dateProperties.indexOf(property) > -1 && this.datePipe.transform(entry[property], 'yyyy-MM-dd').match(phrase.value.toUpperCase())) {
          return true;
      } else if (((entry[property]).toUpperCase().trim().match(phrase.value.toUpperCase().trim()))) {
        return true;
      } else {
        return false;
      }
    });
  }
}
