<div class="row" style="margin-top: 200px;">
    <div class="col-md-offset-2 col-md-8">
        <div class="row">
            <div *ngFor="let item of menuItems">
                <div class="col-md-4" *ngIf="item.openForAll || isSuperAdmin(me)">
                    <div class="card" [routerLink]="[item.routerLink]">
                        <span class="icon"><i class="fa" [ngClass]="item.icon" aria-hidden="true"></i></span>
                        <span class="text">{{item.title | translate}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


