export class Json {
  public static one<T>(type: { new(json: any): T }, json: any): T {
    return new type(json);
  }

  public static many<T>(type: { new(json: any): T }, json: any): T[] {
    return json.map((value) => this.one(type, value));
  }

  public static oneOf<T>(type: { new(json: any): T }) {
    return (value: T[]): T => this.one(type, value);
  }

  public static manyOf<T>(type: { new(json: any): T }) {
    return (value: T[]): T[] => this.many(type, value);
  }
}
