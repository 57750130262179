import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { LogService } from '../services/log.service';
import { InstallationLog } from '../services/entities/installation-log';
import { DatePipe } from '@angular/common';
import { MeService } from '../services/me.service';
import { Me } from '../services/entities/me';
import { InstallationTaskService } from '../services/installation-task.service';
import { Tag } from '../services/entities/tag';
import { TranslateService } from '@ngx-translate/core';
import { FilterAsyncPipe } from 'app/pipes/filter/filter.pipe';
import { SearchTagService } from '../services/search-tag.service';

@Component({
  selector: 'yf-installation-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent implements OnInit, OnDestroy {
  public installationLogs: Observable<InstallationLog[]>;
  public tags = [];
  public tagItems: any[];
  public currentPage = 1;
  public maxItemsPerPage = 20;
  private logService: LogService;
  private datePipe: DatePipe;
  private me: Me;
  private meService: MeService;
  private installationTaskService: InstallationTaskService;
  private translate: TranslateService;
  private filterPipe: FilterAsyncPipe;
  private searchTagService: SearchTagService;
  private installationLogSubscription: Subscription;

  constructor(logService: LogService, datePipe: DatePipe, meService: MeService,
              installationTaskService: InstallationTaskService, translate: TranslateService,
              filterPipe: FilterAsyncPipe, searchTagService: SearchTagService) {
    this.logService = logService;
    this.datePipe = datePipe;
    this.installationLogs = this.logService.installationLogs;
    this.meService = meService;
    this.translate = translate;
    this.meService.getMe().subscribe((me) => {
      this.me = me;
    });
    this.installationTaskService = installationTaskService;
    this.filterPipe = filterPipe;
    this.searchTagService = searchTagService;
  };

  public ngOnInit() {
    this.logService.getInstallationLogs();
    this.installationLogSubscription = this.installationLogs.subscribe((logs) => {
      if (logs.length !== 0) {
        this.getTagItems([]);
      }
    });
  }

  public ngOnDestroy() {
    this.installationLogSubscription.unsubscribe();
  }

  public markAsCompleted(taskId: number): void {
    this.installationTaskService.markAsComplete(taskId).subscribe(() => {
      this.logService.getInstallationLogs();
    });
  }

  public markAsRejected(taskId: number): void {
    this.installationTaskService.markAsRejected(taskId).subscribe(() => {
      this.logService.getInstallationLogs();
    });
  }

  public isYFAdmin(me: Me): boolean {
    return me.type === 'YF_SUPER_ADMIN';
  }

  public getTitleFor(item: InstallationLog) {
    if (item.rejected) {
      return 'Rejected by Fleetmonitor.';
    } else if (item.completed) {
      return 'Completed by Fleetmonitor.';
    } else {
      return 'Not yet handled by Fleetmonitor.';
    }
  }

  public addRemoveTag(): void {
    this.installationLogs.subscribe( (logs) => {
      if (logs.length !== 0) {
        this.getTagItems(this.tags);
      }
    }).unsubscribe();
  }

  private getTagItems(tags) {
    this.searchTagService.getSearchTags(tags).subscribe((tagItems) => {
      tagItems = tagItems.map((tagItem) => {
        let translatedKey = this.translate.instant('tags.' + tagItem.property);

        return new Tag({
          value: tagItem.property + '|' + tagItem.value,
          property: tagItem.property,
          display: translatedKey + ': ' + tagItem.value
        });
      });

      this.tagItems = tagItems;
    });
  }
}
