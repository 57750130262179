import { Component } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { LoggedInService } from '../services/logged-in.service';
import { HttpCache } from '../app.cache';

@Component({
  selector: 'yf-login',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent {
  public username: string;
  public ssoEnv = false;
  public password: string;

  private authService: AuthService;
  private loggedInService: LoggedInService;
  private router: Router;
  private httpCache: HttpCache;
  private ssoHosts = ['ssotest', 'sso-installation', 'mol-installation'];

  constructor(authService: AuthService, loggedInService: LoggedInService, router: Router, httpCache: HttpCache) {
    this.authService = authService;
    this.loggedInService = loggedInService;
    this.router = router;
    this.httpCache = httpCache;
    let host = window.location.host.split('.');
    this.ssoEnv = host.length > 1 ? this.ssoHosts.indexOf(host[0]) > -1 : false;
  }

  public login() {
    this.httpCache.clear();
    this.authService.login(this.username, this.password).subscribe(() => {
      this.redirectToApp();
    }, (error) => {
      if (error.status === 400) {
        alert('Bad credentials, please login at monitoryourfleet to verify your account.');
      }
    });
  }

  private redirectToApp() {
    this.loggedInService.isLoggedIn = true;
    this.router.navigate(['']);
  }
}
