import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Tag } from './entities/tag';

@Injectable()
export class SearchTagService {
  private http: HttpClient;
  private serializer: DomSanitizer;
  private url = '/api/search-tag';

  constructor(http: HttpClient, serializer: DomSanitizer) {
    this.http = http;
    this.serializer = serializer;
  }

  public getSearchTags(tags): Observable<Tag[]> {
    let queryParams = '';
    if (tags.length > 0) {
      for (let i = 0; i < tags.length; i++) {
        if (tags[i].property === undefined) {
          tags[i].property = 'vagueSearch';
        }
        let tag = tags[i].property;
        let value = tags[i].value.replace(tag + '|', '');
        value = this.serializer.sanitize(4, value);

        if (i === 0) {
          queryParams += `?tags=${tag}&values=${value}`;
        } else {
          queryParams += `&tags=${tag}&values=${value}`;
        }
      }
    }

    return this.http.get<Tag[]>(this.url + queryParams);
  }
}
