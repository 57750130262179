<h2>{{'home.installation_sites' | translate}}</h2>
<table class="table table-bordered table-responsive table-striped">
    <thead>
        <tr>
            <th>{{'sites.name' | translate}}</th>
            <th class="small-column text-center">{{'sites.options' | translate}}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let site of installationSites | async">
            <td class="td-with-padding">{{site.name}}</td>
            <td>
                <button class="btn btn-primary btn-sm" (click)="deleteSite(site)"> <i class="fa fa-trash" ></i> {{'generic.delete' | translate}}</button>
            </td>
        </tr>
        <tr>
            <td>
                <input type="text" class="form-control input-sm" id="siteName" placeholder="{{'sites.new' | translate}}" [(ngModel)]="installationSite.name">
            </td>
            <td class="text-center">
                <button class="btn btn-primary btn-block btn-sm" (click)="create(installationSite)" [disabled]="!installationSite.name"> <i class="fa fa-plus" ></i> {{'generic.add' | translate}}</button>
            </td>
        </tr>
    </tbody>
</table>

