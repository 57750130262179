import { Installer } from '../services/entities/installer';

export class TypeaheadInstaller extends Installer {
  public name: string;

  constructor(name: string, values: Object = {}) {
    super(values);
    this.name = name;
  }
}
