import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Me } from './entities/me';

@Injectable()
export class MeService {
  private http: HttpClient;
  private url = '/api/me';

  constructor(http: HttpClient) {
    this.http = http;
  }

  public getMe(): Observable<Me> {
    return this.http.get<Me>(this.url);
  }
}
