import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InstallationTask } from '../../../services/entities/installation-task';

@Component({
  selector: 'yf-installation-form-placement',
  templateUrl: './placement.component.html',
  styleUrls: ['./placement.component.scss']
})
export class InstallationFormPlacementComponent {
  @Output() public installationTaskChange = new EventEmitter<InstallationTask>();
  @Input() public isReadonly: boolean;

  @Input()
  get installationTask() {
    return this.installationTaskValue;
  }

  set installationTask(val) {
    this.installationTaskValue = val;
    this.installationTaskChange.emit(this.installationTaskValue);
  }

  private installationTaskValue: InstallationTask;

  public setMarkerPosition(event) {
    if (!this.isReadonly) {
      const TOP_OFFSET = 38;
      const LEFT_OFFSET = 28;

      this.installationTask.imageMarkerPositionY = Math.round(((event.offsetY - TOP_OFFSET) / event.target.offsetHeight) * 100);
      this.installationTask.imageMarkerPositionX = Math.round(((event.offsetX - LEFT_OFFSET) / event.target.offsetWidth) * 100);
    }
  }

  public getMarkerStyleFor(y, x) {
    if (y === undefined || x === undefined) {
      return {};
    }

    return {
      top: `${y}%`,
      left: `${x}%`,
      display: 'block'
    };
  }

  public isValid(installationTask: InstallationTask): boolean {
    if (installationTask.imageMarkerPositionY === undefined || installationTask.imageMarkerPositionX === undefined) {
      return false;
    }
    return true;
  }
}
