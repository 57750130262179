import { Component, OnInit } from '@angular/core';
import { LoggedInService } from '../services/logged-in.service';
import { NavigationEnd, Router } from '@angular/router';
import { MeService } from '../services/me.service';
import { Me } from '../services/entities/me';
import { filter } from 'rxjs/operators';
import { HasFilesChangedService } from '../services/hasFilesChanged.service';

@Component({
  selector: 'yf-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public breadcrumbs = [];
  public me: Me = new Me();

  private loggedInService: LoggedInService;
  private router: Router;
  private meService: MeService;
  private hasFilesChangedService: HasFilesChangedService;

  constructor(loggedInService: LoggedInService,
              router: Router,
              meService: MeService,
              hasFilesChangedService: HasFilesChangedService) {

    this.loggedInService = loggedInService;
    this.router = router;
    this.meService = meService;
    this.hasFilesChangedService = hasFilesChangedService;
  }

  public ngOnInit(): void {
    this.meService.getMe().subscribe((me) => {
      this.me = me;
    });
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe(this.onNavigationEnd.bind(this));
  }

  public logout() {
    const logEditUrls = ['/log/install/', '/log/move/', '/log/remove/', '/log/replace/'];
    const isLogEditPage = logEditUrls.some(url => this.router.url.startsWith(url));
    if (isLogEditPage && this.hasFilesChangedService.hasFilesChanged) {
      this.loggedInService.preparingToLogout = true;
      this.router.navigate(['']);
      return;
    }
    this.loggedInService.logout();
  }

  private onNavigationEnd(event: NavigationEnd) {
    this.meService.getMe().subscribe((me) => {
      this.me = me;
    });
    if (event.urlAfterRedirects === '/') {
      this.breadcrumbs = [];
      return;
    }

    let urlBreadCrumbs = event.url.split('/')
      .filter(this.isNotEmpty)
      .filter(this.isNotNumber)
      .map(this.eachUrl);

    this.breadcrumbs = this.getDefaultBreadcrumb().concat(urlBreadCrumbs);
    this.breadcrumbs[this.breadcrumbs.length - 1].active = true;
  }

  private getDefaultBreadcrumb(): any {
    return [
      {text: 'breadcrumbs.home', path: '/', active: false}
    ];
  }

  private isNotEmpty(url: string) {
    return url !== '';
  }

  private isNotNumber(url: string) {
    return !new RegExp('^[0-9]+$').test(url);
  }

  private eachUrl(url: string) {
    return {
      text: 'breadcrumbs.' + url,
      path: url,
      active: false
    };
  }
}
