<nav class="navbar">
    <div class="pull-right">
        <i class="me-info">{{me.name}} | {{me.customerName}}</i>
        <button class="btn btn-primary" style="margin: 15px 15px 0 0" (click)="logout()" data-cy="logout">Logout</button>
    </div>

    <div class="container">
        <div class="buttons-container pull-left">
            <a routerLink="installation" class="create-installation" title="Register new installation">
                <i class="fa fa-plus-square-o fa-2x inline-block pointer" *ngIf="breadcrumbs.length > 0"></i>
            </a>
        </div>
        <div class="breadcrumb-container pull-left">
            <ol class="breadcrumb smaller-breadcrumbs inline-block">
                <li *ngFor="let breadcrumb of breadcrumbs" [ngClass]="{'active': breadcrumb.active}">
                    <span [hidden]="!breadcrumb.active">{{breadcrumb.text | translate}}</span>
                    <a [hidden]="breadcrumb.active" [routerLink]="breadcrumb.path">{{breadcrumb.text | translate}}</a>
                </li>
            </ol>
        </div>
    </div>
</nav>

