import { HttpRequest, HttpResponse } from '@angular/common/http';

export class HttpCache {
  private static WHITELISTED_URLS = [
    '/api/me'
  ];

  public clear() {
    const urls = JSON.parse(localStorage.getItem('urls'));

    if (urls) {
      urls.map((url) => {
        localStorage.removeItem(url);
      });

      localStorage.removeItem('urls');
    }
  }

  public get(req: HttpRequest<any>): HttpResponse<any> {
    const parsedHttpResponse = JSON.parse(localStorage.getItem(req.url));
    return parsedHttpResponse ? new HttpResponse(parsedHttpResponse) : null;
  }

  public put(req: HttpRequest<any>, resp: HttpResponse<any>): void {
    if (this.isCacheUrl(req.url)) {
      this.addToUrlList(req.url);
      localStorage.setItem(req.url, JSON.stringify(resp));
    }
  }

  private isCacheUrl(url: string) {
    return HttpCache.WHITELISTED_URLS.some((whitelistUrl) => {
      return url.indexOf(whitelistUrl) > -1;
    });
  }

  private addToUrlList(url: string) {
    let urls = JSON.parse(localStorage.getItem('urls'));

    if (!urls) {
      urls = [];
    }
    if (urls.indexOf(url) === -1) {
      urls.push(url);
      localStorage.setItem('urls', JSON.stringify(urls));
    }
  }
}
