<div class="form-group">
    <label class="input-label">{{'installation.where' | translate}}</label>
    <i class="fa fa-question-circle" tooltip="Click on the image to mark where the DCTU has been installed." placement="right"></i>
    <div class="text-center img-container" [ngClass]="{'disabled': isReadonly, 'valid': isValid(installationTask)}">
        <i class="fa fa-wifi fa-3x marker" [ngStyle]="getMarkerStyleFor(installationTask.imageMarkerPositionY, installationTask.imageMarkerPositionX)"></i>

        <div class="required-field-block">
            <img class="img-responsive" src="./assets/images/wagon-installations-2-padded.png"
                 (click)="setMarkerPosition($event)">
            <div class="required-icon" [ngClass]="{'disabled': isReadonly}">
                <div class="text">*</div>
            </div>
        </div>
    </div>
</div>
