import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Dctu } from './entities/dctu';
import { Json } from './utils/json';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DctuService {
  private http: HttpClient;
  private url = '/api/dctu';

  constructor(http: HttpClient) {
    this.http = http;
  }

  public getDctus(free = true): Observable<Dctu[]> {
    return this.http.get(`${this.url}?free=${free}`).pipe(
      map(Json.manyOf(Dctu)));
  }
}
